<script setup lang="ts">
interface _VTI_TYPE_WritableComputedRef {
    value: _VTI_TYPE_T;
    /**
     * Type differentiator only.
     * We need this to be in public d.ts but don't want it to show up in IDE
     * autocomplete, so we use a private Symbol instead.
     */
    [RefSymbol]: true;
    readonly effect: _VTI_TYPE_ReactiveEffect;
}
interface EditorFormInputProps {
    label?: string;
    inlineLabel?: boolean;
    placeholder?: string;
    elementClassName?: string;
    containerClassName?: string;
    className?: string;
    inputId?: string;
    inputType?: string;
    step?: number;
    disabled?: boolean;
    withoutLabel?: boolean;
    tooltip?: string;
    autofocus?: boolean;
    required?: boolean;
    translated?: boolean;
    withVariables?: boolean;
    form: _VTI_TYPE_Form;
    model?: _VTI_TYPE_WritableComputedRef;
    inputName: string;
    withNullOption?: boolean;
        variablesSubmenuPosition?: string;
        withoutVariables?: boolean;
        withTemplateVariables?: boolean;
        disabled?: boolean;
    }
    import { Quill, QuillEditor } from '@vueup/vue-quill'
    import { FormInputData } from '#imports'
    import '@vueup/vue-quill/dist/vue-quill.snow.css';
    const { $theme } = useNuxtApp();
    const colors = useColors();
    // Props setup
    
    const props = defineProps({
  label: null,
  inlineLabel: { type: Boolean },
  placeholder: null,
  elementClassName: { default: 'pb-6' },
  containerClassName: null,
  className: null,
  inputId: null,
  inputType: null,
  step: null,
  disabled: { type: Boolean, default: false },
  withoutLabel: { type: Boolean },
  tooltip: null,
  autofocus: { type: Boolean },
  required: { type: Boolean },
  translated: { type: Boolean },
  withVariables: { type: Boolean },
  form: null,
  model: null,
  inputName: null,
  withNullOption: { type: Boolean },
  variablesSubmenuPosition: { default: 'right-0' },
  withoutVariables: { type: Boolean, default: false },
  withTemplateVariables: { type: Boolean, default: false }
});
    // Initialize font sizes
    const fontSizes = ['8px', '9px', '10px', '11px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '36px', '48px', '60px', '72px', '96px'];
    const fontSizePlugin = Quill.imports['attributors/style/size'];
    fontSizePlugin.whitelist = fontSizes;
    Quill.register(fontSizePlugin, true);
    // Form input setup
    const inputData: FormInputData = useFormInput(props, {
        className: $theme('form.textarea'),
    });
    const id = `editor-${Math.random().toString(36).substr(2, 9)}`;
    const quillEditor = ref<any>(null);
    const cursorPosition = ref<number | null>(null);
    // Helper to get Quill instance
    const getQuill = () => quillEditor.value?.getQuill();
    // Editor methods
    const setModel = (content: string) => {
        inputData.model.value = content;
    };
    const setColor = (color: string) => {
        getQuill()?.format('color', color);
    };
    const setFontSize = (fontSize: string) => {
        getQuill()?.format('size', fontSize);
    };
    const onSelectionChange = (range: any) => {
        if (range) cursorPosition.value = range.index;
    };
    const onEditorChange = () => {
        const quill = getQuill();
        if (quill) {
            const range = quill.getSelection();
            onSelectionChange(range);
        }
    };
    const addVariable = (variable: string) => {
        const quill = getQuill();
        if (!quill) return;
        // Get the current selection or use the last known cursor position
        const currentSelection = quill.getSelection();
        const length = quill.getLength();
        let insertPosition;
        if (currentSelection) {
            // If there's an active selection, use that position
            insertPosition = currentSelection.index;
        } else if (cursorPosition.value !== null && cursorPosition.value < length - 1) {
            // Only use stored cursor position if it's valid and not at document end
            insertPosition = cursorPosition.value;
        } else {
            // Default to end of content
            insertPosition = Math.max(0, length - 1);
        }
        // Insert the variable at the determined position
        quill.insertText(insertPosition, `%${variable}%`);
        // Update cursor position after insertion
        const newPosition = insertPosition + variable.length + 2; // +2 for the % symbols
        cursorPosition.value = newPosition;
        // Set focus back to editor and move cursor after inserted text
        quill.focus();
        quill.setSelection(newPosition, 0);
    };
    // Lifecycle hooks
    onMounted(async () => {
        const container = document.getElementById(id);
        const input = container?.querySelector('.ql-editor');
        if (input && container) {
            input.addEventListener('focus', () => container.classList.add('ql-focused'));
            input.addEventListener('blur', () => container.classList.remove('ql-focused'));
            if (props.autofocus) {
                await wait(250);
                input.focus();
            }
        }
        if (props.disabled) {
            getQuill()?.disable();
        }
    });
</script>

<template>
    <FormElement
        :id="id"
        :input-data="inputData"
        class="relative editor-container group/editor-container"
    >
        <slot name="before-input" />
        <div class="relative editor-inner-container">
            <QuillEditor
                ref="quillEditor"
                :key="inputData.elementKey.value"
                theme="snow"
                :content="inputData.model.value"
                :options="{ bounds: `#${id}` }"
                content-type="html"
                :toolbar="`#${id}-toolbar`"
                @update:content="setModel"
                @focus="onEditorChange"
                @blur="onEditorChange"
                @selection-change="onEditorChange"
            >
                <template #toolbar>
                    <div
                        :id="`${id}-toolbar`"
                        class="flex gap-2 p-1 ql-editor-toolbar relative"
                    >
                        <!-- Font size picker -->
                        <div>
                            <Submenu relative-div-class="h-[24px]">
                                <template #default="{ toggle }">
                                    <button
                                        class="flex items-start justify-center !pt-0 ql-fontsize ql-picker"
                                        :data-tooltip="$t('misc.editor.fontSize')"
                                        @click.prevent="toggle"
                                    >
                                        <i class="text-sm fa-regular fa-text-size"></i>
                                    </button>
                                </template>
                                <template #submenu="{ hide }">
                                    <div :class="$theme('submenu.items')">
                                        <span
                                            v-for="fontSize in fontSizes"
                                            :key="fontSize"
                                            :class="$theme('submenu.item', 'cursor-pointer')"
                                            @click.prevent="
                                                setFontSize(fontSize);
                                                hide();
                                            "
                                        >
                                            {{ fontSize }}
                                        </span>
                                    </div>
                                </template>
                            </Submenu>
                        </div>

                        <!-- Text formatting buttons -->
                        <div>
                            <button
                                class="ql-bold"
                                :data-tooltip="$t('misc.editor.bold')"
                            ></button>
                            <button
                                class="ql-italic"
                                :data-tooltip="$t('misc.editor.italic')"
                            ></button>
                            <button
                                class="ql-underline"
                                :data-tooltip="$t('misc.editor.underline')"
                            ></button>
                            <button
                                class="ql-strike"
                                :data-tooltip="$t('misc.editor.strike')"
                            ></button>
                        </div>

                        <!-- List buttons -->
                        <div>
                            <button
                                class="ql-list"
                                value="ordered"
                                :data-tooltip="$t('misc.editor.orderedList')"
                            ></button>
                            <button
                                class="ql-list"
                                value="bullet"
                                :data-tooltip="$t('misc.editor.bulletList')"
                            ></button>
                        </div>

                        <!-- Color picker -->
                        <div class="flex items-center">
                            <Submenu relative-div-class="h-[24px]">
                                <template #default="{ toggle }">
                                    <button
                                        class="ql-color ql-picker"
                                        :data-tooltip="$t('misc.editor.color')"
                                        @click.prevent="toggle"
                                    ></button>
                                </template>
                                <template #submenu="{ hide }">
                                    <div class="flex flex-wrap gap-2 w-[240px] py-2">
                                        <div
                                            v-for="color in colors"
                                            :key="color"
                                        >
                                            <span
                                                class="block w-6 h-6 border-2 border-white rounded-full cursor-pointer hover:shadow"
                                                :style="`background: ${color};`"
                                                @click.prevent="
                                                    setColor(color);
                                                    hide();
                                                "
                                            ></span>
                                        </div>
                                    </div>
                                </template>
                            </Submenu>
                        </div>
                    </div>
                </template>
            </QuillEditor>
            <!-- Variables button -->
            <div
                v-if="!withoutVariables"
                class="absolute top-0 right-0 hidden pt-2 pr-2 group-hover/editor-container:block"
            >
                <span class="inline-block h-[26px] w-[26px] pt-[3px] rounded-full bg-neutral-100 flex items-center justify-center">
                    <FormPartialsVariablesSubmenu
                        :submenu-position="variablesSubmenuPosition"
                        :with-template-variables="withTemplateVariables"
                        icon-color="text-primary"
                        @add-variable="addVariable"
                    />
                </span>
            </div>
        </div>
        <slot name="after-input" />
    </FormElement>
</template>

<style>
    .ql-editor {
        min-height: 140px;
        max-height: 240px;
        border: 1px solid #e5e7eb;
        border-radius: 3px;
        transition: all 0.3s linear;
        background: white;
    }
    .ql-editor:hover {
        border: 1px solid black;
    }
    .ql-bubble .ql-tooltip {
        background-color: #edf2fb;
        transition: background-color 0.3s linear;
    }
    .ql-bubble .ql-tooltip:hover {
        background-color: #edf2fb;
    }
    .ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
        border-bottom-color: #edf2fb;
        transition: border-bottom-color-color 0.3s linear;
    }
    .ql-bubble .ql-tooltip:hover:not(.ql-flip) .ql-tooltip-arrow {
        border-bottom-color: #edf2fb;
    }
    .ql-bubble .ql-stroke {
        stroke: #0d00e8;
    }
    .ql-bubble .ql-toolbar button {
        transition: all 0.3s linear;
        border-radius: 3px;
    }
    .ql-bubble .ql-toolbar button.ql-picker {
        color: #0d00e8;
    }
    .ql-bubble .ql-toolbar button .ql-fill {
        fill: #0d00e8;
    }
    .ql-bubble .ql-toolbar button:hover .ql-bubble .ql-toolbar button.ql-active {
        background: #ccdbfd;
    }
    ,
    .ql-bubble .ql-toolbar button.ql-picker:hover,
    .ql-bubble .ql-toolbar button.ql-active.ql-picker {
        color: #0c0672;
    }
    .ql-bubble .ql-toolbar button:hover .ql-stroke,
    .ql-bubble .ql-toolbar button.ql-active .ql-stroke {
        stroke: #0c0672;
    }
    .ql-bubble .ql-toolbar button:hover .ql-fill,
    .ql-bubble .ql-toolbar button.ql-active .ql-fill {
        fill: #0c0672;
    }
    .ql-focused {
        position: relative;
        z-index: 1;
    }
    .editor-container:hover {
        z-index: 9;
    }
</style>
